* {
  background-color: '#343B3B';
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

body {
  background-color: '#343B3B';
}

/* .main{
    margin-bottom:-200px;
} */
.feed-title {
  font-style: normal;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: -10px;
}
.video-title {
  margin-left: 10px;
  font-style: normal;
  font-size: xx-large;
  font-family: Arial, Helvetica, sans-serif;
}

.footer {
  background-color: lightgray;
  margin-top: 500px;
}
.footer-links {
  padding-top: 1px;
}

.artwork {
  cursor: pointer;
}

.image {
  position: relative;
}

.artwork-overlay {
  position: absolute;
  top: 0;
  left: 16px;
  width: 161px;
  height: 161px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.artwork-overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.artwork-title {
  /* font-size: 1.25em; */
  font-weight: bold;
}

.now-playing-overlay {
  position: absolute;
  top: 0;
  left: 16px;
  width: 161px;
  height: 161px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.rhap_container,
.rhap_container *:focus {
  outline: none;
}

.rhap_container {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.rhap_header {
  color: azure;
}

.rhap_time.rhap_current-time {
  color: azure !important;
}

.rhap_time.rhap_total-time {
  color: azure;
}

.rhap_main-controls {
  color: azure;
}

.song-not-in-playlist {
  font-weight: 100;
}

.playlist-song-name {
  color: rgb(7, 11, 64);
  text-decoration: underline;
  font-weight: 300;
}

.playlist-song-name:hover {
  color: rgb(64, 17, 175);
  cursor: pointer;
}

.add-artwork {
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  border-radius: 50%;
}
